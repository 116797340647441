import HTMLComment from 'react-html-comment';
import React from 'react';

const SignupFormView = ({
	handlePasswordKeyUp,
	handleRegionChange,
	handleSubmit,
	showPhoneNumberError,
	showDobError,
	showImageError,
}) => {
	if (showPhoneNumberError) {
		return (
			<div className='container'>
				<div className='text-center'>Something went wrong, please try again later!</div>
			</div>
		);
	}

	return (
		<div className='container'>
			<div className='card'>
				<div className='card-body'>
					<form autoComplete='off' name='create-account' onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='email' className='required'>
										Email address
									</label>
									<input
										required='required'
										type='email'
										className='form-control'
										id='email'
										name='email'
										aria-describedby='emailHelp'
										placeholder='Enter email'
									></input>
									<small id='emailHelp' className='form-text text-muted'>
										We'll never share your emails with anyone else.
									</small>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group password-type'>
									<label htmlFor='password' className='required'>
										Password
									</label>
									<input
										required='required'
										type='password'
										className='form-control'
										id='password'
										name='password'
										placeholder='Password'
										onKeyUp={handlePasswordKeyUp}
									></input>
									<span id='result'></span>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='age' className='required'>
										Age
									</label>
									<input
										type='number'
										className='form-control'
										id='age'
										name='age'
										placeholder='Enter age'
									></input>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='phoneNo'>Phone number</label>
									<input
										style={{ border: '1.5px solid #ced4da' }}
										name='phoneNo'
										type='text'
										className='form-control'
										placeholder='Enter your phone number'
									></input>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label className='form-check-label' htmlFor='dob'>
										Date of birth
									</label>
									<HTMLComment text="Yes! You're right. Here it's a bug and we appreciate that you tried to debug it. Tell us about this comment :)" />
									<input
										className={`form-control ${showDobError ? 'is-invalid' : ''}`}
										name='dob'
										id='datepicker'
										placeholder='Enter your date of birth'
									/>
									{showDobError && (
										<>
											<div className='invalid-feedback'>
												Incorrect Date of birth format. m/d/Y
											</div>
											<HTMLComment text='Congrats! You found it. Tell us about this comment :)' />
										</>
									)}
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='profile-picture'>Profile picture</label>
									<input
										type='file'
										className={`form-control ${showImageError ? 'is-invalid' : ''}`}
										name='profile-picture'
										id='profile-picture'
									></input>
									{showImageError && (
										<div className='invalid-feedback'>
											Incorrect image format. Supported formats: jpg/jpeg/png
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='county'>Country</label>
									<select className='form-control' id='county' name='county' defaultValue={'Choose'}>
										<option disabled>Choose</option>
										<option>Bucuresti</option>
										<option>Constanta</option>
										<option>Cluj</option>
										<option>Brasov</option>
										<option>Olt</option>
									</select>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='county'>State/Province/Region</label>
									<select
										className='form-control'
										id='region'
										name='region'
										defaultValue={'Choose'}
										onChange={handleRegionChange}
									>
										<option disabled>Choose</option>
										<option>Sector 1</option>
										<option>Sector 2</option>
										<option>Sector 3</option>
										<option>Sector 5</option>
										<option>Sector 6</option>
									</select>
								</div>
							</div>
						</div>

						<div className='form-group form-check'>
							<input
								required='required'
								type='checkbox'
								className='form-check-input'
								id='exampleCheck1'
								name='terms'
							></input>
							<label className='form-check-label required' htmlFor='exampleCheck1'>
								I accept Terms & Conditions
							</label>
							<input type='hidden' name='edgecase' value='0'></input>
						</div>

						<input
							type='submit'
							id='submit'
							name='submit'
							className='btn btn-primary'
							value='Create account'
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SignupFormView;

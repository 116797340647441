import { useEffect, useState } from 'react';

const scripts = ['https://unpkg.com/gijgo@1.9.11/js/gijgo.min.js'];

function loadScript(url) {
	return new Promise((resolve) => {
		let script = document.createElement('script');
		script.type = 'text/javascript';

		if (script.readyState) {
			// only required for IE <9
			script.onreadystatechange = () => {
				if (script.readyState === 'loaded' || script.readyState === 'complete') {
					script.onreadystatechange = null;
					resolve();
				}
			};
		} else {
			//Others
			script.onload = () => {
				resolve();
			};
		}

		script.src = url;
		document.head.appendChild(script);
	});
}

const useScripts = () => {
	const [isLoaded, setLoaded] = useState(false);

	useEffect(() => {
		Promise.all(scripts.map((script) => loadScript(script))).then(() => setLoaded(true));
	}, []);

	return isLoaded;
};

export default useScripts;

import logoBroken from '../../images/omniconvert-dark-broken.png';

const NavbarView = ({ handleLogoClick }) => (
	<div className='d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom custom-box-shadow'>
		<div className='my-0 mr-md-auto font-weight-normal logo-container'>
			<img src={logoBroken} {...(handleLogoClick && { onClick: handleLogoClick })}></img>
		</div>
		<nav className='my-2 my-md-0 mr-md-3'>
			<a className='p-2 text-dark' href='/'>
				Home
			</a>{' '}
			<a className='p-2 text-dark' href='#'>
				Contact
			</a>
		</nav>
		<a className='btn btn-outline-primary' href='/create-account'>
			Sign up
		</a>
	</div>
);

export default NavbarView;

import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import ResultsForm from '../components/ResultsForm';
import initDatePicker from '../scripts/datepicker';

const Results = () => {
	useEffect(() => {
		initDatePicker();
	}, []);

	return (
		<div className='App'>
			<Navbar />
			<ResultsForm />
		</div>
	);
};

export default Results;

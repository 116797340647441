import React from 'react';
import './App.css';
import AppView from './App.view';
export const submittedDataContext = React.createContext();

function App() {
	const [submittedData, setSubmittedData] = React.useState({});

	return (
		<submittedDataContext.Provider value={{ submittedData, setSubmittedData }}>
			<AppView />
		</submittedDataContext.Provider>
	);
}

export default App;

const MainInformationView = () => (
	<div className='card col-12 mb-3 mt-3 p-3'>
		<h4 className='text-center mt-3'>QA Engineering Hiring Test</h4>
		<p className='text-center m-0'>
			Below we have an image repersenting the design required by a client for the "New Account" page.
		</p>
		<p className='text-center m-0'>
			Press "Begin test" and test the implementation of this requirement and report all the issues discovered. You
			should look for both functional and visual issues and we would also like to hear what improvements you would
			suggest.
		</p>
		<p className='text-center m-0'>
			After this, send an email at <a href='mailto:qa-test@omniconvert.com'>qa-test@omniconvert.com</a> with the
			bugs found
		</p>
	</div>
);

export default MainInformationView;

import TopLogo from '../components/TopLogo';
import MainInformation from '../components/MainInformation';
import BeginTest from '../components/BeginTest';

const Home = () => {
	return (
		<div className='App'>
			<div className='container'>
				<div className='row'>
					<TopLogo />
					<MainInformation />
					<BeginTest />
				</div>
			</div>
		</div>
	);
};

export default Home;

import { useContext } from 'react';
import { submittedDataContext } from '../App/App.container';

const ResultsFormView = () => {
	const { submittedData } = useContext(submittedDataContext);

	return (
		<div className='container'>
			<div className='card'>
				<div className='card-body'>
					<form>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='email'>Email address</label>
									<input className='form-control' value={submittedData.email || ''} readOnly></input>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='password'>Password</label>
									<input
										className='form-control'
										value={submittedData.password || ''}
										readOnly
									></input>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='age'>Age</label>
									<input className='form-control' value={submittedData.age || ''} readOnly></input>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='phoneNo'>Phone number</label>
									<input
										className='form-control'
										value={submittedData.phoneNo || ''}
										readOnly
									></input>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label className='form-check-label' htmlFor='dob'>
										Date of birth
									</label>
									<input className='form-control' value={submittedData.dob || ''} readOnly></input>
								</div>
							</div>
							<div className='col-6'>
								<div className='form-group'>
									<label className='form-check-label'>Profile picture</label>
									<div className='custom-file'>
										<input
											className='form-control'
											value={submittedData['profile-picture'].name || ''}
											readOnly
										></input>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='county'>County</label>
									<input className='form-control' value={submittedData.county || ''} readOnly></input>
								</div>
							</div>
							<div className='col-sm-12 col-md-6'>
								<div className='form-group'>
									<label htmlFor='county'>State/Province/Region</label>
									<input className='form-control' value={submittedData.region || ''} readOnly></input>
								</div>
							</div>
							<div className='form-group form-check'>
								<label className='form-check-label' htmlFor='exampleCheck1'>
									I accept Terms & Conditions
								</label>
								<input
									className='form-control'
									value={
										submittedData.terms === 'on' && submittedData.edgecase === '0' ? 'YES' : 'NO'
									}
									readOnly
								></input>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResultsFormView;

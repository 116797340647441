import NavbarView from './Navbar.view';

const handleLogoClick = () => {
	const hiddenEdgecaseInput = document.querySelector('input[name=edgecase]');

	if (!hiddenEdgecaseInput) {
		return;
	}

	hiddenEdgecaseInput.value = 1;
};

function Navbar({ isCreateAccount }) {
	return isCreateAccount ? <NavbarView handleLogoClick={handleLogoClick} /> : <NavbarView />;
}

export default Navbar;

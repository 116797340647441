import template from '../../images/template-client.png';

const BeginTestView = () => (
	<div className='card col-12 template-example'>
		<div className='card-body text-center'>
			<img src={template}></img>
			<a href='/create-account' className='btn btn-primary'>
				Begin test
			</a>
		</div>
	</div>
);

export default BeginTestView;

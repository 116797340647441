import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import SignupForm from '../components/SignupForm';
import useScripts from '../scripts/load-scripts';
import initDatePicker from '../scripts/datepicker';

const CreateAccount = () => {
	const isLoaded = useScripts();

	useEffect(() => {
		if (!isLoaded) {
			return;
		}

		initDatePicker();
	}, [isLoaded]);

	return (
		<div className='App'>
			<Navbar isCreateAccount={true} />
			<SignupForm />
		</div>
	);
};

export default CreateAccount;

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submittedDataContext } from '../App/App.container';
import SignupFormView from './SignupForm.view';

const checkStrength = (password) => {
	let strength = 0;

	const resultElement = document.querySelector('#result');

	resultElement.className = '';

	if (password.length < 6) {
		resultElement.classList.add('short');

		return 'Too short';
	}

	if (password.length > 7) {
		strength += 1;
	}

	/* If password contains both lower and uppercase characters, increase strength value. */
	if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
		strength += 1;
	}

	/* If it has numbers and characters, increase strength value. */
	if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
		strength += 1;
	}

	/* If it has one special character, increase strength value. */
	if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
		strength += 1;
	}

	/* If it has two special characters, increase strength value. */
	if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) {
		strength += 1;
	}

	/* Calculated strength value, we can return messages */
	/* If value is less than 2 */
	if (strength < 2) {
		resultElement.classList.add('weak');

		return 'Weak';
	} else if (strength === 2) {
		resultElement.classList.add('good');

		return 'Good';
	} else {
		if (Math.floor(Math.random() * 100 + 1) < 85) {
			resultElement.classList.add('weak');
		} else {
			resultElement.classList.add('strong');
		}

		return 'Strong';
	}
};

const handlePasswordKeyUp = () => {
	const resultElement = document.querySelector('#result');
	const passwordElement = document.querySelector('#password');

	if (!resultElement || !passwordElement) {
		return;
	}

	resultElement.innerHTML = checkStrength(passwordElement.value);
};

const handleRegionChange = () => {
	const countySelector = document.querySelector('#county');

	if (!countySelector) {
		return;
	}

	countySelector.querySelectorAll('option').forEach((option) => {
		option.removeAttribute('selected');
	});
	countySelector.value = 'Bucuresti';
	countySelector.setAttribute('selected', 'selected');
};

const alterPassword = (password) => {
	if (password.length >= 8) {
		return password.substring(0, 3) + 'a' + password.substring(4, password.length);
	}

	return password;
};

const alterDob = (dob) => {
	if (!dob) {
		return;
	}

	const splittedDate = dob.split('/');
	const month = splittedDate[0] || '';
	let day = splittedDate[1] || '';
	const year = splittedDate[2] || '';

	if (Math.floor(Math.random() * 100) <= 50) {
		day = parseInt(day) + 1;
	}

	return `${day}/${month}/${year}`;
};

function SignupForm() {
	const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
	const [showImageError, setShowImageError] = useState(false);
	const [showDobError, setShowDobError] = useState(false);
	const { setSubmittedData } = useContext(submittedDataContext);
	const navigate = useNavigate();

	const resetForm = () => {
		document.querySelector('form[name="create-account"]').reset();

		const resultElement = document.querySelector('#result');

		if (resultElement) {
			resultElement.innerText = '';
			resultElement.className = '';
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const phoneNumberInputValue = document.querySelector('input[name="phoneNo"]')?.value;

		// Show an error when phone number value is empty and random number is less than 20
		if (!phoneNumberInputValue && Math.floor(Math.random() * 100) < 20) {
			setShowPhoneNumberError(true);
			return;
		}

		const dobInputValue = document.querySelector('#datepicker')?.value;
		const isValidDob = dobInputValue && dobInputValue.includes('/');

		// Date of birth should include "/" character in string
		if (!isValidDob) {
			resetForm();
			setShowDobError(true);
			return;
		}

		const imageFileType = document.querySelector('#profile-picture')?.files[0]?.type.split('image/')[1];
		const validImageFileTypes = ['jpg', 'jpeg'];
		const isValidImageFileType = validImageFileTypes.includes(imageFileType);

		// Show an error when image file type is not jpg or jpeg
		if (!isValidImageFileType) {
			resetForm();
			setShowImageError(true);
			return;
		}

		const passwordInputValue = document.querySelector('#password')?.value;
		const alteredPassword = alterPassword(passwordInputValue);
		const alteredDob = alterDob(dobInputValue);

		const form = event.target;
		const formData = new FormData(form);
		const alteredFormData = Object.fromEntries(formData);
		alteredFormData.password = alteredPassword;
		alteredFormData.dob = alteredDob;

		setSubmittedData(alteredFormData);
		navigate('/results');
	};

	return (
		<SignupFormView
			handlePasswordKeyUp={handlePasswordKeyUp}
			handleRegionChange={handleRegionChange}
			handleSubmit={handleSubmit}
			showPhoneNumberError={showPhoneNumberError}
			showDobError={showDobError}
			showImageError={showImageError}
		/>
	);
}

export default SignupForm;

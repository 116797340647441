import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../../pages';
import CreateAccount from '../../pages/create-account';
import Results from '../../pages/results';

const AppView = () => (
	<Router>
		<Routes>
			<Route exact path='/' element={<Home />} />
			<Route exact path='/create-account' element={<CreateAccount />} />
			<Route exact path='/results' element={<Results />} />
		</Routes>
	</Router>
);

export default AppView;
